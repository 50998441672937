import {
  currencyMap,
  financialsCurrencyList
} from "@/src/common/business-layer/constants"
import { MetricsEntry } from "@/src/common/types"
import { FinancialNode } from "@/src/graphql/types"
import { ExtendedScreenerData } from "@/src/pages/screener"
import { URLFactory } from "@/src/sb/business-layer/factories/URLFactory"
import {
  AnonymousUser,
  LicenceType,
  LoggedInUser,
  MemberProfile,
  OptionItem,
  SidebarMenuItemV2
} from "@/src/sb/business-layer/types"
import { Intelligence } from "@/src/sb/components/Intelligence"
import { NumberedPaginationProps } from "@/src/sb/components/Pagination"
import {
  DATA_PAGE_AUTHORIZED_LICENCE_TIERS,
  MATCH_TO_IGNORE_INTELLIGENCE_SOLUTIONS_BANNER_PLATFORM,
  MATCH_TO_SHOW_INTELLIGENCE_SOLUTIONS_BANNER
} from "@/src/sb/constants"
import { V2_FEATURE_TOGGLES } from "@/src/sb/constants/configs"
import { WEBFLOW } from "@/src/sb/constants/featureFlags"
import { getYear } from "date-fns"
import _, { isArray, isObject, transform } from "lodash"

import { Article } from "../models"

export const isEmpty = (obj: object) =>
  !Object.values(obj).map(Boolean).includes(true)

export const getImageSrc = (article: Article) => {
  if (!article.image) return {}

  if (typeof article.image === "string") {
    return {
      src: article.image,
      srcSet: article.image_src_set || "",
      alt_text: ""
    }
  }
  return {
    src: article.image.source_url,
    srcSet: article.image_src_set || "",
    alt_text: article.image.alt_text
  }
}

const getInitials = (name: string) => {
  const [firstName, lastName] = name.split(" ")
  const last = (lastName || " ")[0].toUpperCase()
  return `${firstName} ${last}.`
}
export const getAuthor = (author: Article["author"]) => {
  if (typeof author === "string") {
    return {
      name: getInitials(author),
      id: -1
    }
  }

  author.name = getInitials(author.name ? author.name : "")
  return author
}

export const filterObject = (
  obj: Record<string, any>,
  desiredFields: string[]
): Record<string, any> | null => {
  const filteredObj = obj
    ? Object.keys(obj)
        .filter((key) => desiredFields.includes(key))
        .reduce((newObj, key) => {
          newObj[key] = obj?.[key]
          return newObj
        }, {} as any)
    : {}

  return Object.keys(filteredObj).length > 0 ? filteredObj : null
}

/**
 * Return true if article is free
 * @param report
 */
export const isReportFree = (report: { type?: string }) => {
  return !report.type || report.type === "free"
}

/**
 * Return true if article is free
 * @param user
 */
export const userHasActiveSubscription = (
  user: Pick<LoggedInUser, "userType"> & Partial<LoggedInUser | AnonymousUser>
) => {
  return user?.userType === "subscriber"
}

/**
 * Return true if webflow is enabled and the current path is a match
 * @param path
 */
export const showIntelligenceBannerOnPath = (
  path: string,
  platformisation?: boolean
): boolean => {
  return Boolean(
    V2_FEATURE_TOGGLES.has(WEBFLOW) &&
      MATCH_TO_SHOW_INTELLIGENCE_SOLUTIONS_BANNER.concat(
        platformisation
          ? []
          : MATCH_TO_IGNORE_INTELLIGENCE_SOLUTIONS_BANNER_PLATFORM
      ).some((match) => match.test(path))
  )
}

const getIntelligenceDestination = (
  typeIsReport: boolean,
  item: any
): string => {
  const datasetIsCountryProfile =
    !typeIsReport && item.countryRiskSlug && item.tabSlug
  const computedSlug = datasetIsCountryProfile
    ? item.countryRiskSlug
    : item.slug

  const destinationSearchParams = new URLSearchParams()
  if (item.tabSlug) destinationSearchParams.set("tab", item.tabSlug)
  if (item.countryRiskSlug) destinationSearchParams.set("dataset", item.slug)

  return typeIsReport
    ? URLFactory.report(item.slug, isReportFree(item))
    : `${
        item.bucketSlug === "datasets"
          ? `/${item.bucketSlug}/${item.slug}/`
          : item.groupSlug
          ? item.bucketSlug
            ? `/${item.bucketSlug}/${item.groupSlug}/${computedSlug}/`
            : URLFactory.dataPage(`${item.groupSlug}/${computedSlug}/`)
          : `/${item.slug}/`
      }${
        Array.from(destinationSearchParams.entries()).length
          ? `?${destinationSearchParams.toString()}`
          : ""
      }`
}

export const transformIntelligenceResponse = (
  intelligenceResponse: any,
  usesSearch: boolean,
  type: "datasets" | "reports" = "reports"
): { records: Array<Intelligence>; pagination?: NumberedPaginationProps } => {
  if (!intelligenceResponse) return { records: [] }

  const intelligenceTypeIsReport = type === "reports"

  if (usesSearch) {
    const _intelligence =
      intelligenceResponse.reports || intelligenceResponse.datasets

    return {
      records:
        _intelligence?.hits.map(
          (item: any): Intelligence => ({
            name: item.name || item.title,
            destination: getIntelligenceDestination(
              intelligenceTypeIsReport,
              item
            ),
            pubDate: intelligenceTypeIsReport ? item.date : null,
            tabSlug: intelligenceTypeIsReport ? null : item.tabSlug,
            countryRiskSlug: intelligenceTypeIsReport
              ? null
              : item.countryRiskSlug,
            countryRiskName: intelligenceTypeIsReport
              ? null
              : item.countryRiskName,
            groupSlug: intelligenceTypeIsReport ? null : item.groupSlug
          })
        ) || [],
      pagination: {
        limit: _intelligence.hitsPerPage as number,
        totalNumberOfRecords: _intelligence.nbHits
      }
    }
  }

  return {
    records: intelligenceResponse.items.map(
      (item: any): Intelligence => ({
        name: item.name || item.title,
        destination: getIntelligenceDestination(intelligenceTypeIsReport, item),
        pubDate: intelligenceTypeIsReport ? item.date : null,
        tabSlug: intelligenceTypeIsReport ? null : item.tabSlug,
        countryRiskSlug: intelligenceTypeIsReport ? null : item.countryRiskSlug,
        countryRiskName: intelligenceTypeIsReport ? null : item.countryRiskName,
        groupSlug: intelligenceTypeIsReport ? null : item.groupSlug
      })
    ),
    pagination: {
      limit: intelligenceResponse.limit,
      totalNumberOfRecords: intelligenceResponse.count
    }
  }
}

export const userHasValidDataLicence = (
  memberProfile: MemberProfile | undefined
) =>
  !!memberProfile &&
  memberProfile.roles.includes("premium") &&
  DATA_PAGE_AUTHORIZED_LICENCE_TIERS.includes(
    memberProfile.corporateSubscriberLicence?.toLowerCase() as LicenceType
  )

export function flattenSidebarItems(
  items: SidebarMenuItemV2[]
): SidebarMenuItemV2[] {
  return _.flatMapDeep(items, (item) => {
    const { children, ...rest } = item
    return children ? [rest, ...flattenSidebarItems(children)] : rest
  })
}

export function flattenOptionItems(options: OptionItem[]): OptionItem[] {
  return _.flatMapDeep(options, (option) => {
    const { children, ...rest } = option
    return children ? [rest, ...flattenOptionItems(children)] : rest
  })
}

export function filterSidebarItems(
  items: SidebarMenuItemV2[],
  searchTerm: string
): SidebarMenuItemV2[] {
  return items
    .map((item): SidebarMenuItemV2 | null => {
      // Recursively filter children
      const matchingChildren = item.children
        ? filterSidebarItems(item.children, searchTerm)
        : []

      // Check if the current item matches the search term
      const isMatch = item.name.toLowerCase().includes(searchTerm.toLowerCase())

      // Include the item if it matches or has matching children
      if (isMatch || matchingChildren.length > 0) {
        return {
          ...item,
          children: matchingChildren
        }
      }

      // Exclude the item if neither it nor its children match
      return null
    })
    .filter((item) => !!item) as SidebarMenuItemV2[] // Type guard to remove null values
}

export function filterOptionItems(
  items: OptionItem[],
  searchTerm: string
): OptionItem[] {
  return items
    .map((item): OptionItem | null => {
      // Recursively filter children
      const matchingChildren = item.children
        ? filterOptionItems(item.children, searchTerm)
        : []

      // Check if the current item matches the search term
      const isMatch = (item?.name ?? item.value)
        .toLowerCase()
        .includes(searchTerm.toLowerCase())

      // Include the item if it matches or has matching children
      if (isMatch || matchingChildren.length > 0) {
        return {
          ...item,
          children: matchingChildren
        }
      }

      // Exclude the item if neither it nor its children match
      return null
    })
    .filter((item) => !!item) as OptionItem[] // Type guard to remove null values
}

/**
 * Converts SidebarMenuItemV2 objects to OptionItem format.
 * @param items Array of SidebarMenuItemV2 items to convert.
 * @returns Converted array of OptionItem<SidebarMenuItemV2>
 */
export function convertSidebarMenuToOptions(
  items: SidebarMenuItemV2[]
): OptionItem<SidebarMenuItemV2>[] {
  return items.map((item) => ({
    value: item.slug,
    id: item.id,
    name: item.name,
    children: item.children
      ? convertSidebarMenuToOptions(item.children)
      : undefined,
    original: item
  }))
}

export function hasValidValue(obj: any): boolean {
  if (Array.isArray(obj)) {
    // Check if at least one item in the array is valid
    return obj.some((item) => hasValidValue(item))
  } else if (typeof obj === "object" && obj !== null) {
    // Check if at least one property in the object is valid
    return Object.values(obj).some((value) => hasValidValue(value))
  }

  // Non-object, non-array values are valid if they are not null or undefined
  return obj !== null && obj !== undefined
}

export function getFlatSidebarMenuItemsWithDestination(
  items: SidebarMenuItemV2[]
): SidebarMenuItemV2[] {
  const result: SidebarMenuItemV2[] = []

  function visitNode(item: SidebarMenuItemV2) {
    // Check if the destination starts with "/open-data"
    if (item.destination && item.destination.startsWith("/open-data")) {
      result.push(item)
    }

    // If the item has children, recursively visit each child
    if (item.children) {
      item.children.forEach(visitNode)
    }
  }

  // Start the recursive visiting process for each item in the root list
  items.forEach(visitNode)

  return result
}

export const getOrCreateEntry = (
  acc: MetricsEntry[],
  metrics: string | number,
  isGroupHeader = false
) => {
  if (!metrics) {
    const newEntry = { metrics: "", isGroupHeader }
    acc.push(newEntry)
    return newEntry
  }

  let entry = acc.find((item) => item.metrics === metrics)
  if (!entry) {
    entry = { metrics, isGroupHeader }
    acc.push(entry)
  }
  return entry
}

export const getFinancialsFiscalYears = (
  edges: { node: FinancialNode }[]
): string[] =>
  edges?.reduce((acc: string[], { node }: { node: FinancialNode }) => {
    const reportingYear = getYear(new Date(node.reportingDate))
    const yearLabel = `${reportingYear} FY`
    if (!acc.includes(yearLabel)) {
      acc.push(yearLabel)
    }
    return acc
  }, [])

export const formatCompanyFiancialsCurrency = (
  value: number | undefined | null
) =>
  value != null
    ? new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(value / 1_000_000)
    : "-"

export const getCurrencyValue = (
  node: FinancialNode,
  key: string,
  currencyPrefix: string
) => {
  const prefixedValue = node[`${key}${currencyPrefix}` as keyof FinancialNode]
  const fallbackValue = node[key as keyof FinancialNode]

  if (prefixedValue === null) {
    return null
  }

  return prefixedValue ?? fallbackValue
}

export const getCurrencyInfo = (currencyCode: string) => {
  const currency = currencyMap[currencyCode]
  if (currency) {
    return `${currency.label} (${currency.symbol})`
  }
  return currencyCode
}

export const financialsCurrencyOptions = (reportingCurrency: string) => {
  return financialsCurrencyList
    .map((option) => {
      if (option.value === "reported_currency" && reportingCurrency) {
        option.label = getCurrencyInfo(reportingCurrency)
        option.value = reportingCurrency
      } else {
        option.label = getCurrencyInfo(option.value.toUpperCase())
      }
      return option
    })
    .filter(
      (option) => !(option.value === "usd" && reportingCurrency === "USD")
    )
    .filter(
      (option) => option.value !== "reported_currency" || reportingCurrency
    )
}

export const formatCellNumericSignedValue = (
  cellValue: string | number | null
): string => {
  const numericValue = parseFloat(cellValue as string)

  return numericValue < 0
    ? `(${Math.abs(numericValue).toFixed(2)})`
    : cellValue !== null && cellValue !== undefined
    ? String(cellValue)
    : "-"
}

export const getCompanyFinancialLimits = (
  type: string,
  min?: number,
  max?: number
) => {
  const toMillions = (value?: number) => (value ? value * 1_000_000 : undefined)
  const toPercentage = (value?: number) => (value ? value / 100 : undefined)
  const toRatio = (value?: number) => value

  switch (type) {
    case "below_1m":
      return { min: undefined, max: 1_000_000 }
    case "between_1m_10m":
      return { min: 1_000_000, max: 10_000_000 }
    case "above_10m":
      return { min: 10_000_000, max: undefined }
    case "greater_than":
      return { min: toMillions(min), max: undefined }
    case "less_than":
      return { min: undefined, max: toMillions(max) }
    case "equal":
      return { min: toMillions(min), max: toMillions(min) }
    case "between":
      return { min: toMillions(min), max: toMillions(max) }

    case "below_0":
      return { min: undefined, max: toPercentage(0) }
    case "between_0_10":
      return { min: toPercentage(0), max: toPercentage(10) }
    case "above_10":
      return { min: toPercentage(10), max: undefined }
    case "below_50":
      return { min: undefined, max: toPercentage(50) }
    case "between_50_100":
      return { min: toPercentage(50), max: toPercentage(100) }
    case "above_100":
      return { min: toPercentage(100), max: undefined }

    case "below_1":
      return { min: undefined, max: toRatio(1) }
    case "between_1_2":
      return { min: toRatio(1), max: toRatio(2) }
    case "above_2":
      return { min: toRatio(2), max: undefined }
    case "between_1_3":
      return { min: toRatio(1), max: toRatio(3) }
    case "above_3":
      return { min: toRatio(3), max: undefined }

    case "below_10":
      return { min: undefined, max: toPercentage(10) }
    case "between_10_20":
      return { min: toPercentage(10), max: toPercentage(20) }
    case "above_20":
      return { min: toPercentage(20), max: undefined }

    default:
      return {}
  }
}

export const calculatePredefinedFoundingYear = (selectedOption: string) => {
  const currentYear = new Date().getFullYear()

  const predefinedRanges: Record<
    string,
    {
      operationName: "Between"
      predefinedOperation: string
      min: number
      max: number
    }
  > = {
    last_5_years: {
      operationName: "Between",
      predefinedOperation: "last_5_years",
      min: currentYear - 5,
      max: currentYear
    },
    last_10_years: {
      operationName: "Between",
      predefinedOperation: "last_10_years",
      min: currentYear - 10,
      max: currentYear
    },
    last_20_years: {
      operationName: "Between",
      predefinedOperation: "last_20_years",
      min: currentYear - 20,
      max: currentYear
    }
  }

  return predefinedRanges[selectedOption] || null
}

export const prepareDealFiltering = (dealFilteringObj: ExtendedScreenerData) =>
  transform(dealFilteringObj, (result: any, value: any, key: string) => {
    if (isArray(value)) {
      result[key] = value.join(", ")
    } else if (typeof value === "string" && /^-?\d+(\.\d+)?$/.test(value)) {
      result[key] = Number(value)
    } else if (isObject(value)) {
      result[key] = prepareDealFiltering(value as ExtendedScreenerData)
    } else {
      result[key] = value
    }
  })
